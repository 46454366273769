.ct-alert-wrapper {
  z-index: 1000;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: white;
  top: 5%;
  left: 30%;
  height: auto;
  width: 600px;
  max-height: inherit;
  max-width: inherit;
  margin-left: inherit;
  margin-right: inherit;
  border-radius: 3px;
}

.ct-alert {
  position: relative;

  &__backdrop {
    position: fixed;
    z-index: 900;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    overflow: hidden;
    pointer-events: initial;
    background-color: rgba(9, 30, 66, 0.54);
  }

  &__header {
    padding: 24px;
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  &__body {
    padding: 0 24px;
    max-height: calc(100vh - 198px);
    overflow: auto;
  }

  &__footer {
    padding: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      margin-left: 8px;
    }
  }
}
