@import 'assets/styles/variables';
@import './../../ProjectPage.scss';

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(#{$FieldWidthRegular});
  margin-right: $CapaPlanMargin;
  color: $Gray7;
  border-bottom: $CapaPlanBorder;

  &__avatar {
    position: relative;

    &-badge {
      position: absolute;
      bottom: -3px;
      left: -7px;
    }
  }

  &-role {
    @include Caption1;
    color: $Gray7;
  }

  &-username {
    @include Body2-2;
    color: $PrimaryColor7;
    display: flex;
    align-items: center;
    padding: 6px 0 3px;

    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 140px;
      overflow: hidden;
      text-align: center;
    }
  }

  &-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: $SizeMedium;
    background-color: $Gray0;
    border-top: $CapaPlanBorder;
    border-radius: 2px;

    @include Caption1;

    &__time {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 50%;
      border-right: $CapaPlanBorder;
    }
  }
}
