@import 'assets/styles/variables.scss';

.container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &-card {
    z-index: 90;
    background-color: $Gray0;
    width: 100%;
    max-width: 720px;
    min-width: 200px;
    height: 100%;
    height: auto;
    margin-top: 2rem;
    padding: 1rem;
    box-shadow: $ShadowCard;
  }

  &-tracker {
    margin-bottom: $SizeExtraLarge;
    display: flex;
    justify-content: center;
  }
}

.email-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-bottom: $SizeExtraLarge;

  &__img {
    background-size: cover;
    background-image: url('../../assets/img/undraw_newsletter_vovu.svg');
    width: 200px;
    height: 200px;
  }
}

.background-container {
  position: fixed;
  width: 100%;
  height: 100%;
  margin-top: 20%;
  margin-left: 5%;
  &__image {
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/img/onboarding_grafik.svg');
    background-repeat: no-repeat;
  }
  &__bar {
    z-index: 0;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: $Gray3;
    width: 100%;
    height: 30%;
  }
}

.ct-box {
  text-align: center;
  padding:  2rem 0;
  &__logo {
    text-decoration: none;
    color: #0052cc;
    margin-bottom: 0.4rem;
  }
  &__title {
    font-size: 0.92rem;
    font-weight: 600;
    margin-bottom: 4rem;
  }
  &__contact {
    margin-bottom: 4rem;
    h4 {
      font-size: 0.92rem;
      margin-top: 0.8rem;
      font-style: italic;
      font-weight: 600;
    }
    &-btn {
      width: 200px !important;
    }
  }
  &__voucher {
    p {
      font-size: 0.92rem;
      margin-bottom: 0.8rem;
    }
    &-btn {
      border: 1px solid #0052cc !important;
      background: #d5e6ff !important;
    }
  }
}
