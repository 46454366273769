@import 'assets/styles/variables';

$CapaPlanBorder: 0px solid $Gray7;

$weekdaysWidth: $FieldWidthRegular/3;

$sprintCardWidth: $FieldWidthRegular;

$LegendeWidth: 12rem;

$CapaPlanMargin: 0.2rem;

* {
  box-sizing: border-box;
}

body {
  background-color: $Gray1;
}

.header {
  padding-left: 2rem;
  position: sticky;
  top: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 10rem;
  background-color: $Gray1;
}

.users {
  padding-left: calc(4.16667rem + 12.5rem);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: $Gray1;

  //FIXME: Maybe better way to write this selector --> Selects the First "Vormittagsfeld"
  .userInfo:first-child
    > .userInfo-container
    > .userInfo-container__time:first-child {
    box-sizing: content-box;
    border-left: $CapaPlanBorder;
    border-right: 0;
  }

  //FIXME: Maybe better way to write this selector --> Selects the First "Nachmittagsfeld"
  .userInfo:first-child
    > .userInfo-container
    > .userInfo-container__time:last-child {
    box-sizing: content-box;
    border-left: $CapaPlanBorder;
  }
}

.headSpacer {
  min-width: calc(#{$weekdaysWidth} + #{$sprintCardWidth});
  background-color: #ffffff;
  height: 32px;
  align-self: flex-end;
}

.wrapperSpacer {
  min-width: $LegendeWidth;
  background-color: $Gray1;
}

.newUserSpacer {
  width: $FieldWidthRegular;
}

.wrapper {
  padding-left: 2rem;
  justify-content: flex-start;
  display: flex;
  margin-bottom: $FieldHeightRegular/2;
  width: auto;
}

.legende {
  top: 28vh;
  position: sticky;
  min-width: $LegendeWidth;
  max-width: $LegendeWidth;
  margin-top: 2vh;
}

.plan {
  display: flex;
  flex-direction: row;
}

.footer {
  background-color: lightgoldenrodyellow;
  grid-area: footer;
}

.sprintCard {
  background-color: white;
  display: flex;
  justify-content: right;
  min-width: $sprintCardWidth;
  max-width: $sprintCardWidth;
}

.week {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: $weekdaysWidth;
}

.loadingContainer {
  display: flex;
  height: 80vh;
  justify-content: center;
  align-items: center;
}

.lazyLoadingContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.loadSprintWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.main__info {
  &-block {
    position: sticky;
    top: 10%;
    height: calc(100vh - 120px);
    margin-top: 4rem;
  }

  &-skeleton {
    background-color: white;
    padding: 6px;
    margin-bottom: 1.4rem;
  }
}

.skeleton-table {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.team-skeleton {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  &__item {
    margin: 0 4.2rem;
  }
}

.sprint-skeleton {
  background-color: white;
  padding: 8px;
  margin-right: 1.4rem;

  &--block {
    display: flex;
  }
}

.sprint-showhide-btn {
  font-size: 14px !important;
}

.time {
  &__calc {
    width: 100%;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      flex: 1;
    }

    &-select {
      flex: 2;

      .react-select__control {
        background-color: transparent;
        border-color: transparent;
      }
    }
  }
}

.main {
  display: flex;

  &--sidebar {
    display: flex;
    padding: 0 0 0 1.6rem;
    background-color: #f1f1f3;
    border-right: 1px solid $Gray3;
    margin-right: 1.4rem;
  }

  &--content {
    flex: 1;
  }

  &__team {
    padding-top: 1rem;
    display: grid;
    grid-template-columns: 266px 1fr;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    top: 58px;
    background: #f1f1f1;
    z-index: 99;
    margin-bottom: 3.6rem;

    &-block {
      flex: 1 1 0%;
      display: inline-flex;
    }
  }

  &__head {
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    align-items: flex-end;
    left: 0;
    top: 0;
    background: #f1f1f1;
    z-index: 9;

    &-btn {
      margin-bottom: 0.4rem;
    }
  }

  &__info {
    margin-right: 1.6rem;
    width: 230px;
  }

  &__sprint {
    display: flex;
    margin-bottom: 1.6rem;
  }

  &__project-dtl {
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    top: 0;
    background: #f1f1f1;
    z-index: 9;
  }

  &__project-content {
    display: flex;
  }
}
