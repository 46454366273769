@import 'assets/styles/variables';

.startpage {
  text-align: center;
  padding: 2rem 0 3rem;

  &__content {
    background-color: white;
    padding: 1.8rem;
    max-width: 768px;
    width: 100%;
    max-height: 500px;
    display: flex;
    height: 100%;
    border-radius: 3px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;

    &-title {
      padding: 1.2rem 0 0.6rem;

      span {
        color: $MainColor1;
      }
    }

    &-desc {
      padding-bottom: 1.6rem;
      font-size: 14px;
      color: $Gray8;
    }
  }
}
