@import 'assets/styles/variables.scss';

.Worktype-info {
  h4 {
    font-size: 14px;
  }
  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding-top: 0.3125rem;
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0.3px;

    li {
      display: flex;
      align-items: center;
      padding-top: 1rem;
      flex-direction: row;
    }

    li p {
      font-size: 0.75rem;
      font-weight: 400;
      letter-spacing: 0.3px;
      margin-left: 0.5rem;
    }
  }
}
