@import 'assets/styles/variables.scss';

.capa-overview {
  background-color: white;
  border: 1px solid $Gray3;
  margin-bottom: 1rem;

  &__title {
    padding: 0.5rem 0.6rem 0rem;
    display: flex;
    font-size: 12px;
    justify-content: space-between;

    h4 {
      font-size: 14px;
    }
  }

  &--btn {
    background: none;
    border: 0;
    cursor: pointer;

    span {
      height: 14px;
    }
  }

  &-table {
    padding: 0 0 0.6rem 0;
  }

  &__block {
    max-height: 6.4rem;
    overflow: auto;
  }

  &--head {
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    margin-top: 0.6rem;
    border-bottom: 1px solid $Gray3;
    border-top: 1px solid $Gray3;
    padding: 0.4rem 0.6rem;
    font-weight: 600;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    margin-top: 0.6rem;

    span:first-child {
      padding-left: 0.6rem;
    }

    span:last-child {
      padding-right: 2.8rem;
    }
  }
}
