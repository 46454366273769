@import 'assets/styles/variables';
@import './../../ProjectPage.scss';

.weekdays {
  width: 100%;
  border-top: $CapaPlanBorder;
  border-left: $CapaPlanBorder;

  .sumIconBorder {
    padding: 2px;
    background-color: #ffffff;
    height: $FieldHeightRegular;

    .sumIcon {
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $Gray1;
      height: 100%;
      font-weight: $FontWeightSemiBold;
    }
  }
}
