@import 'assets/styles/variables';

.form-footer .delete-link {
  color: $SignalColorError5 !important;
  background: none !important;
  text-decoration: underline;

  &:hover {
    background: none !important;
    color: $SignalColorError5 !important;
    text-decoration: underline;
  }
}

.form {
  &-field {
    margin-bottom: 0.7rem;

    label {
      font-size: 0.8571428571428571em;
      font-style: inherit;
      line-height: 1.3333333333333333;
      color: $Gray8;
      font-weight: 600;
      margin-top: 16px;
      display: inline-block;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
      margin-bottom: 4px;
      margin-top: 0;

      span {
        color: $SignalColorError5;
      }
    }

    .error {
      background-color: #fafbfc;
      border: 2px solid $SignalColorError5;
    }
  }

  &-table {
    table,
    button {
      font-size: 14px;
    }
  }

  &-sprint-info {
    margin-top: 1rem;

    h4 {
      color: $Gray8;
      font-size: 14px;
      margin-bottom: 0.4rem;
      display: flex;
      align-items: center;
    }

    p {
      color: $Gray7;
      font-size: 12px;
      margin-bottom: 0.6rem;
    }
  }

  &-footer {
    padding: 1.4rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-btn-group {
    display: flex;
    align-items: center;
  }

  &-btn--save {
    margin-left: 0.7rem;
  }
}

.sprint-select-all {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: $MainColor1 !important;
  text-decoration: underline !important;
}

.sprint-list {
  column-count: 3;
  font-size: 14px;
  max-height: 278px;
  overflow: auto;

  label {
    display: block !important;

    span {
      position: relative;
      top: -7px;
    }
  }
}

.alert {
  font-size: 14px!important;
  margin-top: 0.6rem!important;
  margin-bottom: 0.6rem!important;
} 
.alert-danger {
  color: red!important;
}
