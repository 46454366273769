@import 'assets/styles/variables.scss';

.c-avatar {
  &--group {
    display: flex;
  }

  &__item {
    margin: 0px -6px 0px 0px;

    &.red {
      background-color: $SignalColorError4;

      &:hover {
        background-color: $SignalColorError5;
      }
    }

    &.yellow {
      background-color: $SignalColorWarning4;

      &:hover {
        background-color: $SignalColorWarning5;
      }
    }

    &.green {
      background-color: $SignalColorSuccess4;

      &:hover {
        background-color: $SignalColorSuccess5;
      }
    }

    &.blue {
      background-color: $SignalColorInfo4;

      &:hover {
        background-color: $SignalColorInfo5;
      }
    }

    p {
      border: 2px solid white;
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      justify-content: center;
      border-radius: 50%;
      background-color: $Gray5;
      color: white;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
    }
  }

  &__dd_item {
    font-weight: 500;

    p {
      margin-right: 6px;
    }
  }
}
