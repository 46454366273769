@import 'assets/styles/variables';

.ct {
  &__calendar {
    background-color: white;
    padding: 1rem;

    .fc-toolbar-title {
      font-weight: 400;
      font-size: 22px;
      color: $Gray11;
    }

    .fc-col-header-cell-cushion {
      color: $Gray11;
      font-weight: 400;
      font-size: 12px;
      text-transform: uppercase;
    }

    .fc-daygrid-day-number {
      padding: 8px;
      font-size: 12px;
      font-weight: 500;
    }

    .fc-header-toolbar {
      margin-bottom: 0.8rem !important;
    }

    .fc-daygrid-day-top {
      justify-content: center;
    }

    .fc-daygrid-event {
      cursor: pointer;
      font-weight: 600;
    }

    .fc-daygrid-day.fc-day-today {
      background-color: rgba(0, 82, 204, 0.16);
      border-top: 3px solid rgba(0, 82, 204, 1);
    }

    .fc-daygrid-day-events {
      position: absolute !important;
      left: 0;
      right: 0;
    }
  }
}
