@import '../../../../assets/styles/variables.scss';

.ct-dropdown {
  position: relative;

  &__head {
    cursor: pointer;
    padding: 0 6px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    background-color: $Gray2;
  }

  &__list {
    position: absolute;
    top: 40px;
    width: 160px;
    right: 0;
    box-shadow: 0 0 6px 2px $Gray3;
    z-index: 1;
    background-color: white;
    border-radius: 3px;
    overflow: hidden;
    display: none;

    &-item {
      padding: 0.8rem 1rem;
      cursor: pointer;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background-color: $Gray1;
      }
    }

    &.active {
      display: block;
    }
  }
}
