@import 'assets/styles/variables';

.card {
  width: 100%;
  height: 100%;
  padding: $SizeRegular;
  box-shadow: $ShadowCard;
  background-color: $Gray0;
  @include Caption1;
  color: $Gray7;
}

.name {
  @include Body1-2;
  color: $SignalColorInfo5;

  p {
    max-width: 128px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.sprint-name {
  p {
    padding-left: 4px;
    padding-bottom: 0.0999rem;
  }
}

.points {
  @include Body2-2;
  padding-top: $SizeRegular;
  color: $Gray13;
}

.iteration {
  @include Caption1;
  padding-top: $SizeSmall;
}

.sprint-ref {
  width: 140px;
  margin: 1rem 0;

  &--btn {
    display: flex;
    cursor: pointer;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 2px;
    padding: 6px 4px;
    border: 1px dashed $Gray4;
    @include transition(all 100ms ease-in-out);

    &:hover {
      border-color: $Gray8;
    }
  }

  &__title {
    color: $Gray13;
    padding-left: 4px;
  }
}

.sprint-goal {
  margin-top: 0.6rem;
  .name {
    color: #333;
    font-size: 12px;
    padding-bottom: 0.2rem;
  }

  textarea {
    font-size: 12px;
  }
}

.sprint-events {
  &__btn {
    background-color: $Gray8 !important;
  }

  &__heading {
    display: flex;
    align-items: center;
    margin-bottom: 0.4rem;

    &-title {
      font-size: 14px;
      color: $Gray12;
      font-weight: 600;
      padding-right: 6px;
    }
  }

  &__empty {
    border: 1px solid $Gray2;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-block {
    max-height: 6.4rem;
    overflow: auto;
  }

  &__item {
    border: 1px solid $Gray2;
    padding: 0.5rem;
    border-radius: 3px;
    margin-bottom: 0.6rem;
    cursor: pointer;

    &:hover {
      border: 1px solid $PrimaryColor3;
    }

    &-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.3rem;

      h3 {
        font-size: 0.82rem;
        color: $Gray9;
        font-weight: 600;
      }
    }

    &-date {
      margin-bottom: 0.2rem;
    }

    &-date,
    &-time {
      display: flex;
      align-items: center;

      p {
        color: $Gray8;
        padding-left: 2px;
      }
    }
  }
}

.sprint-hashtag {
  margin-bottom: 1rem;
}
.sprint-close-goal-textarea {
  cursor: pointer;
  margin-top: 5px;
  margin-right: 5px;
  float: right;
  z-index: 10;
  border: 0.5px solid rgb(235, 235, 235);
  border-radius: 10%;
  background-color: rgb(253, 253, 253);
  padding: 5px 5px;
  box-shadow: 5px 5px rgb(233, 225, 225);
}
