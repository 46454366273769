@import 'assets/styles/variables';

.getting-started {
  border: 2px solid $Gray1;
  padding: 6px 10px;
  border-radius: 500rem;
  text-align: center;
  cursor: pointer;
  position: fixed;
  display: block;
  left: 0;
  bottom: 0;
  background-color: $Gray10;
  color: $Gray1;
  z-index: 100;
  margin: 1.2rem;

  span {
    font-size: 0.84rem;
    font-weight: 600;
  }
}
