// Gray-Colors
$Gray0: rgb(255, 255, 255);
$Gray1: rgb(241, 241, 243);
$Gray2: rgb(236, 237, 239);
$Gray3: rgb(218, 219, 224);
$Gray4: rgb(199, 201, 208);
$Gray5: rgb(180, 183, 192);
$Gray6: rgb(162, 165, 177);
$Gray7: rgb(143, 147, 161);
$Gray8: rgb(124, 128, 145);
$Gray9: rgb(87, 92, 114);
$Gray10: rgb(68, 74, 98);
$Gray11: rgb(49, 56, 82);
$Gray12: rgb(31, 38, 67);
$Gray13: rgb(12, 20, 51);

// Background-Colors
$BackgroundColor: rgb(248, 248, 249);
$ContentBackgroundColor: rgb(255, 255, 255);
$BackdropColor: rgba(31, 38, 67, 0.8);
$NavBarColor: rgb(255, 255, 255);

// Main Colors
$MainColor1: rgb(0, 82, 204);
$MainColor2: rgb(23, 43, 77);
$MainColor3: rgb(255, 255, 255);

// Primary colors
$PrimaryColor0: rgb(240, 241, 255);

$PrimaryColor1: rgb(230, 232, 255);
$PrimaryColor2: rgb(216, 220, 255);
$PrimaryColor3: rgb(179, 186, 255);
$PrimaryColor4: rgb(154, 164, 255);
$PrimaryColor5: rgb(128, 141, 255);

$PrimaryColor6: rgb(112, 124, 223);
$PrimaryColor7: rgb(96, 106, 191);

// Secondary colors
$SecondaryColor0: rgb(255, 251, 240);

$SecondaryColor1: rgb(255, 247, 224);
$SecondaryColor2: rgb(255, 236, 179);
$SecondaryColor3: rgb(255, 224, 128);
$SecondaryColor4: rgb(255, 192, 0);
$SecondaryColor5: rgb(255, 156, 0);

$SecondaryColor6: rgb(237, 145, 0);
$SecondaryColor7: rgb(219, 134, 0);

$DisabledColor: $Gray4;

// Signal-Colors
$SignalColorError1: rgb(255, 212, 225);
$SignalColorError2: rgb(255, 188, 208);
$SignalColorError3: rgb(255, 131, 167);
$SignalColorError4: rgb(255, 75, 128);
$SignalColorError5: rgb(245, 34, 96);

$SignalColorWarning1: rgb(255, 240, 198);
$SignalColorWarning2: rgb(255, 232, 170);
$SignalColorWarning3: rgb(255, 223, 135);
$SignalColorWarning4: rgb(255, 211, 92);
$SignalColorWarning5: rgb(253, 199, 0);

$SignalColorSuccess1: rgb(219, 255, 239);
$SignalColorSuccess2: rgb(182, 246, 218);
$SignalColorSuccess3: rgb(118, 235, 184);
$SignalColorSuccess4: rgb(43, 234, 153);
$SignalColorSuccess5: rgb(0, 219, 97);

$SignalColorInfo1: rgb(227, 232, 255);
$SignalColorInfo2: rgb(205, 214, 255);
$SignalColorInfo3: rgb(161, 174, 235);
$SignalColorInfo4: rgb(101, 119, 208);
$SignalColorInfo5: rgb(61, 78, 165);

// Font-Colors
$FontColorInverted: rgb(255, 255, 255);
$FontColorRecommended: rgb(128, 141, 255);

$FontColor1: rgb(199, 201, 208);
$FontColor2: rgb(162, 165, 177);
$FontColor3: rgb(87, 92, 114);
$FontColor4: rgb(49, 56, 82);
