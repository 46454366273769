@import 'assets/styles/variables.scss';

.events__btn {
  margin-top: 2rem;
}

.flex {
  display: flex;
}

.abbrev {
  color: $Gray8;
  padding-left: 0.3rem;
  font-size: 14px;
  transform: translateY(3px);
}
