@import 'assets/styles/variables';
@import './../../ProjectPage.scss';

.container-user {
  display: flex;
  align-items: center;
  top: 0;
  position: absolute;
  right: 20px;
  flex-direction: column;
  cursor: pointer;

  &:hover .container-user-button {
    background-color: $Gray7;
    transition: all 200ms ease-in-out;
  }

  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    min-width: 46px;
    min-height: 46px;
    border-radius: 50%;
    font-weight: $FontWeightSemiBold;
    border: 3px solid white;
    background-color: $Gray6;
    transition: all 200ms ease-in-out;
  }

  &-title {
    font-size: $FontSizeRegular;
    font-weight: $FontWeightSemiBold;
    letter-spacing: 0.15px;
    color: $Gray7;
    display: flex;
    align-items: center;
    padding: 6px 0 3px;
  }
}
