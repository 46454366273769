.intro {
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #eee;
  margin-right: 1.2rem;

  &__container {
    text-align: center;
  }

  &__img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('../../assets/img/intro_img.svg');
    width: 100%;
    height: 180px;
  }

  &__title {
    margin: 0.8rem 0 0.6rem;
    font-size: 20px;
  }

  &__desc {
    margin-bottom: 1.2rem;
    font-size: 14px;
    color: #777;
  }

  &__btn {
    position: inherit !important;
  }
}
