@import 'assets/styles/variables';

.sumFieldBorder {
  padding: 2px;
  background-color: #ffffff;
  min-width: $FieldWidthRegular;
  max-width: $FieldWidthRegular;
  height: $FieldHeightRegular;

  .sumField {
    color: #ffffff;
    border-radius: 2px;
    background-color: $PrimaryColor5;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $FontWeightSemiBold;
    user-select: none;
    position: relative;

    &--disable:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(266, 266, 266, 0.5);
    }
  }
}
