@import 'assets/styles/variables';

.secondary-btn {
  margin-right: 0.6rem;
}

.ref {
  &-table {
    table th {
      background-color: $PrimaryColor1;
      color: $Gray11;
      font-size: 12px;
    }

    table td {
      text-align: center;
      font-size: 14px;
    }
  }

  &-info {
    font-size: 12px;
    color: $Gray9;
    line-height: 20px;

    &__url {
      margin-top: 0.4rem;
    }

    &__url-item {
      display: flex;
      align-items: center;
      padding-top: 0.6rem;
    }

    &__url-item span {
      font-weight: 500;
      margin-right: 1rem;
    }

    &__url-btn {
      display: flex;
      align-items: center;
      padding-top: 4px;

      h4 {
        padding-right: 6px;
        color: $PrimaryColor6;
      }

      button {
        transform: translateY(1px);
      }
    }

    &__input-num {
      width: 30px;
      margin: 0 10px;
      input {
        width: 100%;
        text-align: center;
      }
    }
  }

  &-table__values {
    margin-bottom: 0.6rem;
    align-items: flex-end;

    &-item {
      margin-right: 1rem;

      .input-small {
        background-color: $Gray1;
      }
    }

    h5 {
      font-size: 12px;
      color: $Gray10;
      margin-bottom: 0.3rem;
    }

    .select-small {
      width: 100%;
      font-size: 12px;

      .react-select__control {
        min-height: 26px;
        height: 26px;
      }

      .react-select__value-container {
        height: 22px;
      }

      .react-select__indicators {
        height: 22px;
      }

      .react-select__input {
        transform: translateY(-2px);
      }
    }
  }
}

.c-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: collapse;

  &--bordered {
    border: 1px solid #dee2e6;

    td,
    th {
      border: 1px solid #dee2e6;
    }

    thead td,
    thead th {
      border-bottom-width: 2px;
    }
  }

  td,
  th {
    padding: 0.3rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
}

.event-info {
  &__item {
    margin-bottom: 1.2rem;

    h4 {
      margin-bottom: 0.4rem;
      font-weight: 600;
    }

    span {
      color: $Gray9;
      font-size: 14px;
    }

    p {
      color: $Gray9;
      font-size: 14px;
    }

    a {
      font-size: 14px;
    }
  }

  &__calendar {
    display: flex;
    align-items: center;

    &-date,
    &-time {
      display: flex;
      align-items: center;

      span:first-child svg {
        width: 20px;
        height: 20px;
        transform: translateY(3px);
      }
    }

    &-date {
      padding-right: 1rem;
    }
  }

  &__interval {
    display: flex;
    align-items: center;
    margin-bottom: 0.6rem;
  }
}

.form-fields {
  font-size: 14px;
}

.form-row {
  display: flex;
  margin-top: 8px;
  margin-bottom: 16px;

  &__col {
    flex: 1;
  }
}

.field-member {
  display: flex;
  align-items: center;

  &__name {
    padding-left: 4px;
  }
}

.hashtag {
  margin-top: 2rem;

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 0.6rem;
    border-top: 1px solid $Gray2;
    padding-top: 0.6rem;
    max-height: 6.4 rem;
    overflow: auto;
  }

  &__item {
    background-color: $Gray1;
    padding: 6px 12px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 0.9rem;
    margin-bottom: 0.9rem;
    color: $Gray10;

    h5 {
      font-size: 14px;
      font-weight: 500;
      margin-right: 10px;
    }
  }

  &__btn {
    font-size: 12px !important;
    margin-left: 6px;
    margin-bottom: 6px;
  }
}
