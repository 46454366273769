@import 'assets/styles/variables';
@import './../../../ProjectPage.scss';

.capaField {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-shrink: 3;
  border-radius: 2px;

  &:hover {
    cursor: pointer;
  }
}

.capaFieldYellow {
  background-color: $SignalColorWarning1;
}

.capaFieldRed {
  background-color: $SignalColorError2;
}

.capaFieldGreen {
  background-color: $SignalColorSuccess2;
}

.capaFieldGray {
  background-color: $Gray2;
}

.capaBorder {
  width: 50%;
  height: $FieldHeightRegular;
  background-color: white;
  border-bottom: $CapaPlanBorder;
  border-right: $CapaPlanBorder;
  padding: 2px;
  position: relative;

  &--disable:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(266, 266, 266, 0.8);
  }
}

.capa-event-icon {
  position: absolute;
  cursor: pointer;
  top: 6px;
  right: 6px;
  font-size: 12px;
  color: $MainColor1;
  border-radius: 50%;
  background-color: white;
  border: 1px solid $PrimaryColor2;
  padding: 1px;
}

// Dropdown
.capa-event {
  &__menu {
    background-color: white;
    border-radius: 2px;
    box-shadow: 0 1px 4px 0px $Gray4;
    display: none;
    transform: translateY(-4px);
    right: 0;
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 10;

    &.active {
      display: block;
    }
  }

  &__item {
    align-items: center;
    justify-content: flex-end;
    border-radius: 0px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex: 0 0 auto;
    background-color: white;
    color: rgb(23, 43, 77);
    text-decoration: none;
    padding: 8px 12px 7px;
    width: auto;
    font-size: 14px;
    margin: 3px 0;

    &:hover {
      background-color: $Gray1;
    }
  }
}
