@import 'assets/styles/variables';

.ct-modal {
  position: relative;

  &__backdrop {
    position: fixed;
    z-index: 500;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    overflow: hidden;
    pointer-events: initial;
    background-color: rgba(9, 30, 66, 0.54);
  }

  &__container {
    z-index: 500;
    position: fixed;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
  }

  &__block {
    width: 100%;

    &--xlarge {
      max-width: 968px;
    }

    &--large {
      max-width: 800px;
    }

    &--medium {
      max-width: 600px;
    }

    &--small {
      max-width: 480px;
    }
  }

  &__content {
    background-color: white;
    border-radius: 3px;
  }

  &__header {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__body {
    padding: 0 24px;
    max-height: calc(100vh - 198px);
    // overflow: auto;
  }

  &__footer {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--border {
      border-top: 1px solid $Gray3;
      width: 100%;
      padding-top: 1rem;
      display: flex;
      justify-content: space-between;
    }
  }
}
