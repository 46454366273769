@import './../../assets/styles/variables.scss';

.container-navbar {
  top: 0;
  position: fixed;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  height: 3.6rem;
  box-shadow: $Shadow4;
  z-index: 100;
  justify-content: space-between;
  padding: 0 1.2rem;

  &__logo {
    a {
      text-decoration: none;
      color: $MainColor1;
    }
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__item {
    padding-left: 1rem;
    a {
      text-decoration: none;
    }
    .single-select {
      width: 64px;
    }
  }
}

.main-menu {
  margin-left: 2rem;
  font-size: 14px;
  display: flex;
  align-items: center;

  &__item {
    margin-left: 0.8rem;
    a {
      text-decoration: none;
      color: $Gray12;

      button {
        padding: 0 4px;
        position: relative;
      }

      &.active button span {
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: -13px;
          left: 0;
          width: 100%;
          background-color: $Gray6;
          height: 2px;
          z-index: 99999;
        }
      }
    }
  }
}

.Toastify__toast-container--top-right {
  top: 5em;
}
