@import './../../assets/styles/variables.scss';

.feedback {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 100;

  &-container {
    width: $FieldWidthRegular;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &-button {
      &:hover {
        cursor: pointer;
        background-color: $PrimaryColor6;
      }

      &:active {
        background-color: $PrimaryColor7;
      }

      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      min-width: 44px;
      min-height: 44px;
      border-radius: 2px;
      padding: 10px;
      font-weight: $FontWeightSemiBold;
      border: 3px solid #ffffff;
      background-color: $PrimaryColor5;
      margin-bottom: 22px;
    }
  }
}

.feedback {
  &__logo {
    color: $MainColor1;
  }
  &__item {
    margin: 1rem 0;

    h5 {
      font-size: 0.86rem;
      color: #6b778c;
      font-weight: 600;
    }

    div div label {
      font-size: 14px;

      span {
        color: $Gray8;
      }
    }
  }
  &__emoji {
    display: flex;
    align-items: center;
    margin-top: 0.6rem;

    &-item {
      margin-right: 24px;
      border: 3px solid transparent;
      cursor: pointer;
      border-radius: 50%;
      transition: all 140ms ease-in-out;

      &:hover {
        border: 3px solid $MainColor1;
        transition: all 140ms ease-in-out;
      }

      &.checked {
        border: 3px solid $MainColor1;
      }
    }
  }

  &__textarea {
    margin-top: 18px;
  }

  &__radio {
    display: flex;
    align-items: center;
    margin-top: 18px;
  }
}
